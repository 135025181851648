import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "team",
    component: () => import("@/views/team/index.vue"),
  },
  {
    path: "/individual",
    name: "individual",
    component: () => import("@/views/home/index.vue"),
  },
  {
    //学校链接報名
    path: "/signup",
    name: "signup",
    component: () => import("@/views/signup/index.vue"),
  },
  {
    //个人報名
    path: "/signup_p",
    name: "signup_p",
    component: () => import("@/views/signup_p/index.vue"),
  },
  {
    //報名成功
    path: "/successful",
    name: "successful",
    component: () => import("@/views/signup_successful/index.vue"),
  },
  {
    //誦材
    path: "/material",
    name: "material",
    component: () => import("@/views/material/index.vue"),
  },
  {
    //wa
    path: "/wa",
    name: "wa",
    component: () => import("@/views/wa/index.vue"),
  },
  {
    //成绩公示
    path: "/achievement",
    name: "achievement",
    component: () => import("@/views/achievement/index.vue"),
  },
  {
    //紫荆杯2
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    //紫荆杯2
    path: "/cs",
    name: "cs",
    component: () => import("@/views/cs/index.vue"),
  },
  {
    //团体赛
    path: "/team",
    name: "team",
    component: () => import("@/views/team/index.vue"),
  },
  {
    //誦材
    path: "/material_team",
    name: "material_team",
    component: () => import("@/views/material_team/index.vue"),
  },
  {
    //成绩公示
    path: "/achievement_team",
    name: "achievement_team",
    component: () => import("@/views/achievement_team/index.vue"),
  },
];

const router = new VueRouter({
  // mode: 'hash',
  mode: "history", //去掉# 打包上传的时候使用，
  base: process.env.BASE_URL,
  routes,
});
// 全局路由
router.beforeEach((to, from, next) => {
  next();
});

export default router;
