import { mapMutations } from 'vuex'

export default {
	created() { 
		const resizeEvt =
		  'orientationchange' in window ? 'orientationchange' : 'resize'
		const resetRecalc = () => {
		  const __clientWidth =
		    document.documentElement.clientWidth || document.body.clientWidth
			// console.log('__clientWidth',__clientWidth)

		  if (__clientWidth < 1024) {
			// console.log('参数',this.store)
		    this.$store.commit('SET_ISPC', false);   
		  } else {
			// console.log('参数2',this.store)
		    this.$store.commit('SET_ISPC', true);
		  }
		}
		window.addEventListener(resizeEvt, resetRecalc.bind(this), false)
		window.addEventListener('load', resetRecalc.bind(this), false)
	},
	methods: {
		...mapMutations(['SET_ISPC']), 
	} 
}
